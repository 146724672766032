<template>
    <div class="dream-board-page">
        <div class="center-box">

        </div>
    </div>
</template>

<script>
import { ref, onMounted } from 'vue';

export default {
    name: 'DreamBoardTwo',
    setup() {
        const setVH = () => {
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        }

        onMounted(() => {
            setVH();
            window.addEventListener('resize', setVH);
        });
    }
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Grape+Nuts&display=swap');

.dream-board-page {
    width: 100%;
    height: 100vh;
    background: transparent;
    color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    box-sizing: border-box;
    position: relative;
}

.center-box {
    width: 90%;
    max-width: 500px;
    height: calc(var(--vh) * 80);
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
}
</style>
