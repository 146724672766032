<template>
    <div class="container">
        <router-view v-slot="{ Component }">
            <component :is="Component" />
        </router-view>
    </div>
</template>

<script>
import { onMounted, onBeforeUnmount, watch } from 'vue'
import { useRoute } from 'vue-router'


export default {
    name: 'App',
    setup() {
        const route = useRoute();

        // 스크롤이 필요한 라우트 경로 설정
        const scrollableRoutes = ['/dreamboard']; // 스크롤이 필요한 라우트의 경로를 추가하세요

        const updateOverflow = (isScrollable) => {
            const overflowValue = isScrollable ? 'scroll' : 'hidden';
            document.documentElement.style.overflow = overflowValue;
            document.body.style.overflow = overflowValue;
        };

        // 현재 라우트에 따라 overflow 속성 변경
        const checkScrollableRoute = () => {
            const isScrollable = scrollableRoutes.includes(route.path);
            updateOverflow(isScrollable);
        };

        // 라우트 변경 감지 및 처리
        watch(route, () => {
            checkScrollableRoute();
        });

        // 컴포넌트가 마운트될 때 초기 설정
        onMounted(() => {
            checkScrollableRoute();
        });

        // 컴포넌트가 언마운트될 때 overflow 초기화
        onBeforeUnmount(() => {
            updateOverflow(false);
        });

        return {};
    }
}
</script>

<style>
* {
    margin: 0;
    padding: 0;
}

html,
body {
    background-color: rgb(245 245 245 / 20%);
}

.container {
    max-width: 400px;
    margin: 0 auto;
    box-sizing: border-box;
    height: 100vh;
    background-color: white;
}
</style>
