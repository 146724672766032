import {createRouter, createWebHashHistory} from "vue-router";

import Ios from "@views/Ios.vue"
import Landing from "@views/Landing.vue"
import Survey from "@views/Survey.vue"
import UserInfoForm from "@views/UserInfoForm.vue" 
import DreamBoard from "@views/DreamBoard.vue"



export const routes = [
    {path: '/', component: Landing, name: 'Landing'},
    {path: '/ios',component:Ios, name:'Ios'},
    {
        path: '/survey',
        component: Survey,
        name: 'Survey',
        props: route => ({ currentQuestionIndex: parseInt(route.query.index) || 0 }) 
    },
    {path: '/userinfoform',component:UserInfoForm, name:'UserInfoForm'},
    {path: '/dreamboard',component:DreamBoard, name:'DreamBoard'}

]

const router = createRouter({
    history: createWebHashHistory(),
    routes,
})

export default router;