<template>
    <div class="language-toggle">
        <input type="checkbox" id="toggle" v-model="isEnglish" />
        <label for="toggle" class="toggle-label">
            <span class="toggle-text left">KOR</span>
            <span class="toggle-text right">ENG</span>
            <span class="toggle-ball">
                <svg xmlns="http://www.w3.org/2000/svg" width="48" height="42" viewBox="0 0 48 42" fill="none">
                    <ellipse cx="24" cy="21" rx="24" ry="21" fill="#FAFFBF" fill-opacity="0.8" />
                </svg>
            </span>
        </label>
    </div>
</template>


<script>
import { computed } from 'vue';
import { useLanguageStore } from '../stores/language';

export default {
    setup() {
        const languageStore = useLanguageStore();

        // isEnglish를 Pinia 스토어의 상태와 연동
        const isEnglish = computed({
            get: () => languageStore.isEnglish,
            set: (value) => languageStore.setLanguage(value),
        });

        return {
            isEnglish,
        };
    },
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Gowun+Dodum&display=swap');

.language-toggle {
    position: absolute;
    top: 13px;
    right: 9px;
    width: 99px;
    height: 44px;
    border-radius: 40px;
    border: 1px solid rgba(0, 0, 0, 0.20);
    background: rgba(250, 255, 191, 0.20);
    cursor: pointer;
    transition: background-color 0.3s;
    z-index: 20;
}

.language-toggle input {
    opacity: 0;
    width: 0;
    height: 0;
}

.toggle-label {
    display: block;
    width: 100%;
    height: 100%;
}

.toggle-ball {
    position: absolute;
    top: 2px;
    background-color: rgba(0, 0, 0, 0);
    border: none;
    border-radius: 50%;
    transition: transform 0.3s;
}


.language-toggle input:checked+.toggle-label .toggle-ball {
    transform: translateX(50px);
}

.toggle-text {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: #000;
    user-select: none;
    z-index: 2;
    color: #000;
    text-align: center;
    font-family: "Gowun Dodum";
    font-size: 12.5px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: -0.25px;
}

.toggle-text.left {
    left: 15px;
}

.toggle-text.right {
    right: 15px;
}
</style>