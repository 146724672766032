<template>
    <div class="dream-board-page">
        <div class="header-text">{{ headerText }}</div>
        <div class="dream-board-container">
            <div class="overlay-center">
                <img src="@resource/dream_board/dreamboard.png" class="overlay-image" />
                <div class="image-container">
                    <div class="image-wrapper odd">
                        <img :src="selectedIdolImage" class="image-item" />
                        <img src="@resource/dream_board/sticker.png" class="sticker" />
                    </div>
                    <div class="image-wrapper even">
                        <img :src="selectedFoodImage" class="image-item" />
                        <img src="@resource/dream_board/sticker2.png" class="sticker" />
                    </div>
                    <div class="image-wrapper odd">
                        <img :src="selectedChallengeImage" class="image-item" />
                        <img src="@resource/dream_board/sticker3.png" class="sticker" />
                    </div>
                    <div class="image-wrapper even">
                        <img :src="selectedImportantImage" class="image-item" />
                        <img src="@resource/dream_board/sticker4.png" class="sticker" />
                    </div>
                    <div class="image-wrapper odd">
                        <img :src="selectedHouseImage" class="image-item" />
                        <img src="@resource/dream_board/sticker.png" class="sticker" />
                    </div>
                    <div class="image-wrapper even">
                        <img :src="selectedCountryImage" class="image-item" />
                        <img src="@resource/dream_board/sticker2.png" class="sticker" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onMounted, computed } from 'vue';

export default {
    name: 'DreamBoardOne',
    setup() {
        const foodImage = ref([
            new URL('@resource/dream_board/one/food_img1.png', import.meta.url).href,
            new URL('@resource/dream_board/one/food_img2.png', import.meta.url).href,
            new URL('@resource/dream_board/one/food_img3.png', import.meta.url).href,
            new URL('@resource/dream_board/one/food_img4.png', import.meta.url).href,
            new URL('@resource/dream_board/one/food_img5.png', import.meta.url).href,
        ]);

        const challengeImage = ref([
            new URL('@resource/dream_board/two/challenge_img1.png', import.meta.url).href,
            new URL('@resource/dream_board/two/challenge_img2.png', import.meta.url).href,
            new URL('@resource/dream_board/two/challenge_img3.png', import.meta.url).href,
            new URL('@resource/dream_board/two/challenge_img4.png', import.meta.url).href,
            new URL('@resource/dream_board/two/challenge_img5.png', import.meta.url).href,
        ]);

        const importantImage = ref([
            new URL('@resource/dream_board/three/important_img1.png', import.meta.url).href,
            new URL('@resource/dream_board/three/important_img2.png', import.meta.url).href,
            new URL('@resource/dream_board/three/important_img3.png', import.meta.url).href,
            new URL('@resource/dream_board/three/important_img4.png', import.meta.url).href,
            new URL('@resource/dream_board/three/important_img5.png', import.meta.url).href,
        ]);

        const houseImage = ref([
            new URL('@resource/dream_board/four/house_img1.png', import.meta.url).href,
            new URL('@resource/dream_board/four/house_img2.png', import.meta.url).href,
            new URL('@resource/dream_board/four/house_img3.png', import.meta.url).href,
        ]);

        const countryImage = ref([
            new URL('@resource/dream_board/five/country_img1.png', import.meta.url).href,
            new URL('@resource/dream_board/five/country_img2.png', import.meta.url).href,
            new URL('@resource/dream_board/five/country_img3.png', import.meta.url).href,
            new URL('@resource/dream_board/five/country_img4.png', import.meta.url).href,
            new URL('@resource/dream_board/five/country_img5.png', import.meta.url).href,
        ]);

        const idolImage = ref([
            new URL('@resource/dream_board/six/idol_img1.png', import.meta.url).href,
            new URL('@resource/dream_board/six/idol_img2.png', import.meta.url).href,
            new URL('@resource/dream_board/six/idol_img3.png', import.meta.url).href,
            new URL('@resource/dream_board/six/idol_img4.png', import.meta.url).href,
            new URL('@resource/dream_board/six/idol_img5.png', import.meta.url).href,
            new URL('@resource/dream_board/six/idol_img6.png', import.meta.url).href,
        ]);

        const headerText = ref('');

        const surveySelections = ref([]);

        const selectedIdolImage = computed(() => {
            const selection = surveySelections.value[1];
            if (selection && selection >= 1 && selection <= idolImage.value.length) {
                return idolImage.value[selection - 1];
            } else {
                return idolImage.value[0];
            }
        });

        const selectedFoodImage = computed(() => {
            const selection = surveySelections.value[2];
            if (selection && selection >= 1 && selection <= foodImage.value.length) {
                return foodImage.value[selection - 1];
            } else {
                return foodImage.value[0];
            }
        });

        const selectedChallengeImage = computed(() => {
            const selection = surveySelections.value[5];
            if (selection && selection >= 1 && selection <= challengeImage.value.length) {
                return challengeImage.value[selection - 1];
            } else {
                return challengeImage.value[0];
            }
        });

        const selectedImportantImage = computed(() => {
            const selection = surveySelections.value[10];
            if (selection && selection >= 1 && selection <= importantImage.value.length) {
                return importantImage.value[selection - 1];
            } else {
                return importantImage.value[0];
            }
        });

        const selectedCountryImage = computed(() => {
            const selection = surveySelections.value[12];
            if (selection && selection >= 1 && selection <= countryImage.value.length) {
                return countryImage.value[selection - 1];
            } else {
                return countryImage.value[0];
            }
        });

        const selectedHouseImage = computed(() => {
            const selection = surveySelections.value[13];
            if (selection && selection >= 1 && selection <= houseImage.value.length) {
                return houseImage.value[selection - 1];
            } else {
                return houseImage.value[0];
            }
        });

        const setVH = () => {
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        }


        onMounted(() => {
            setVH();
            window.addEventListener('resize', setVH);

            const userInfo = localStorage.getItem('userInfo');
            if (userInfo) {
                const userNickname = JSON.parse(userInfo).nickname;
                headerText.value = `${userNickname}'s Dreamboard`;
            }

            const selections = localStorage.getItem('surveySelections');
            if (selections) {
                surveySelections.value = JSON.parse(selections);
            } else {
                surveySelections.value = [];
            }
        });

        return {
            headerText,
            selectedIdolImage,
            selectedFoodImage,
            selectedChallengeImage,
            selectedImportantImage,
            selectedCountryImage,
            selectedHouseImage,
        };
    }
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Gowun+Dodum&display=swap');

.dream-board-page {
    width: 100%;
    height: calc(var(--vh) * 92.5);
    background: transparent;
    color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    box-sizing: border-box;
}

.header-text {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 4%;
    color: #F6E123;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: rgba(0, 0, 0, 0.60);
    font-family: 'Gowun Dodum', sans-serif;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    letter-spacing: -0.6px;
}

.dream-board-container {
    position: relative;
    width: 100%;
    height: auto;
}

.overlay-center {
    position: relative;
    width: 95%;
    height: auto;
    margin: 0 auto;
    z-index: 1;
    border: none;
    top: 55%;
    transform: translateY(-50%);
}

.overlay-image {
    width: 100%;
    height: auto;
    border: none;
    box-shadow: none;
}

.image-container {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 85%;
    height: 85%;
    transform: translate(-50%, -50%);
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-items: center;
    align-items: center;
    z-index: 2;
    grid-row-gap: -1%;
}

.image-wrapper {
    position: relative;
    width: 80%;
    height: auto;
    max-width: 150px;
}

.image-item {
    width: 100%;
    height: auto;
    display: block;
    box-shadow: 0px 6px 3px 2px rgba(0, 0, 0, 0.25);
}

.sticker {
    position: absolute;
    width: 75px;
    height: auto;
}

.image-wrapper.odd .sticker {
    top: -20px;
    left: -20px;
}

.image-wrapper.even .sticker {
    top: -20px;
    right: -20px;
}
</style>
