import { defineStore } from 'pinia';

const imagePaths = {
    kPop: new URL('@resource/survey/one/k-pop.png', import.meta.url).href,
    kBeauty: new URL('@resource/survey/one/k-beauty.png', import.meta.url).href,
    kFood: new URL('@resource/survey/one/k-food.png', import.meta.url).href,
    kDrama: new URL('@resource/survey/one/k-drama.png', import.meta.url).href,
    kFashion: new URL('@resource/survey/one/k-fashion.png', import.meta.url).href,
    oneElse: new URL('@resource/survey/one/else.png', import.meta.url).href,
    newJeans: new URL('@resource/survey/two/new-jeans.png', import.meta.url).href,
    bts: new URL('@resource/survey/two/bts.png', import.meta.url).href,
    blackPink: new URL('@resource/survey/two/black-pink.png', import.meta.url).href,
    rise: new URL('@resource/survey/two/rise.png', import.meta.url).href,
    nct: new URL('@resource/survey/two/nct.png', import.meta.url).href,
    ive: new URL('@resource/survey/two/ive.png', import.meta.url).href,
    budae: new URL('@resource/survey/three/budae.png', import.meta.url).href,
    buldak: new URL('@resource/survey/three/buldak.png', import.meta.url).href,
    kimchi: new URL('@resource/survey/three/kimchi.png', import.meta.url).href,
    ganjang: new URL('@resource/survey/three/ganjang.png', import.meta.url).href,
    tteokbokki: new URL('@resource/survey/three/tteokbokki.png', import.meta.url).href,
    threeElse: new URL('@resource/survey/three/else.png', import.meta.url).href,
    gentleMonster: new URL('@resource/survey/four/gentle-monster.png', import.meta.url).href,
    instantfunk: new URL('@resource/survey/four/instantfunk.png', import.meta.url).href,
    emis : new URL('@resource/survey/four/emis.png', import.meta.url).href,
    ader: new URL('@resource/survey/four/ader.png', import.meta.url).href,
    woo: new URL('@resource/survey/four/woo.png', import.meta.url).href,
    else: new URL('@resource/survey/else.png', import.meta.url).href,
    tamburins: new URL('@resource/survey/five/tamburins.png', import.meta.url).href,
    amuse: new URL('@resource/survey/five/amuse.png', import.meta.url).href,
    DrG: new URL('@resource/survey/five/DrG.png', import.meta.url).href,
    jungsaem: new URL('@resource/survey/five/jungsaem.png', import.meta.url).href,
    peripera: new URL('@resource/survey/five/peripera.png', import.meta.url).href,
    cook: new URL('@resource/survey/six/cook.png', import.meta.url).href,
    friend: new URL('@resource/survey/six/friend.png', import.meta.url).href,
    home: new URL('@resource/survey/six/home.png', import.meta.url).href,
    korean: new URL('@resource/survey/six/korean.png', import.meta.url).href,
    economic: new URL('@resource/survey/seven/test.png', import.meta.url).href,
    language: new URL('@resource/survey/seven/language.png', import.meta.url).href,
    around: new URL('@resource/survey/seven/around.png', import.meta.url).href,
    courage: new URL('@resource/survey/seven/courage.png', import.meta.url).href,
    motivation: new URL('@resource/survey/seven/motivation.png', import.meta.url).href,
    oneHour: new URL('@resource/survey/eight/one-hour.png', import.meta.url).href,
    twoHour: new URL('@resource/survey/eight/two-hour.png', import.meta.url).href,
    threeHour: new URL('@resource/survey/eight/three-hour.png', import.meta.url).href,
    fourHour: new URL('@resource/survey/eight/four-hour.png', import.meta.url).href,
    oneMoney: new URL('@resource/survey/nine/one-money.png', import.meta.url).href,
    twoMoney: new URL('@resource/survey/nine/two-money.png', import.meta.url).href,
    threeMoney: new URL('@resource/survey/nine/three-money.png', import.meta.url).href,
    fourMoney: new URL('@resource/survey/nine/four-money.png', import.meta.url).href,
    checkList: new URL('@resource/survey/ten/check-list.png', import.meta.url).href,
    objective: new URL('@resource/survey/ten/objective.png', import.meta.url).href,
    reward: new URL('@resource/survey/ten/reward.png', import.meta.url).href,
    routine: new URL('@resource/survey/ten/routine.png', import.meta.url).href,
    success: new URL('@resource/survey/ten/success.png', import.meta.url).href,
    family: new URL('@resource/survey/eleven/family.png', import.meta.url).href,
    hobby: new URL('@resource/survey/eleven/hobby.png', import.meta.url).href,
    work: new URL('@resource/survey/eleven/work.png', import.meta.url).href,
    korea: new URL('@resource/survey/twelve/korea.png', import.meta.url).href,
    japan: new URL('@resource/survey/twelve/japan.png', import.meta.url).href,
    france: new URL('@resource/survey/twelve/france.png', import.meta.url).href,
    usa: new URL('@resource/survey/twelve/usa.png', import.meta.url).href,
    singapore: new URL('@resource/survey/twelve/singapore.png', import.meta.url).href,
    twelveElse: new URL('@resource/survey/twelve/else.png', import.meta.url).href,
    apartment: new URL('@resource/survey/fourteen/apartment.png', import.meta.url).href,
    fourteenElse: new URL('@resource/survey/fourteen/else.png', import.meta.url).href,
};

export const useImageStore = defineStore({
    id: 'image',
    state: () => ({
        ...imagePaths,
    }),
    actions: {
        getImage(key) {
            return this[key];
        },
    }
});



