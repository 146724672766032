export const englishQuestions = [
    {
        text: '1. Which genre of Korea\nare you most interested in?',
        icons: [
            { text: '1. K-pop', imageKey: 'kPop' },
            { text: '2. K-beauty', imageKey: 'kBeauty' },
            { text: '3. K-food', imageKey: 'kFood' },
            { text: '4. K-drama', imageKey: 'kDrama' },
            { text: '5. K-fashion', imageKey: 'kFashion' },
            { text: '6. Other', imageKey: 'oneElse' }
        ]
    },
    {
        text: "2. Who's your favorite K-celebrity?",
        icons: [
            { text: '1. New Jeans', imageKey: 'newJeans' },
            { text: '2. BTS', imageKey: 'bts' },
            { text: '3. Blackpink', imageKey: 'blackPink' },
            { text: '4. Riize', imageKey: 'rise' },
            { text: '5. IVE', imageKey: 'ive' },
            { text: '6. NCT 127', imageKey: 'nct' }
        ]
    },
    {
        text: "3.What's your favorite Korean food?",
        icons: [
            { text: '1. Budae Jjigae', imageKey: 'budae' },
            { text: '2. Buldak Noodles', imageKey: 'buldak' },
            { text: '3. Kimchi Pancake', imageKey: 'kimchi' },
            { text: '4. Soy sauce Crab', imageKey: 'ganjang' },
            { text: '5. Tteokbokki', imageKey: 'tteokbokki' },
            { text: '6. Other', imageKey: 'threeElse' }
        ]
    },
    {
        text: "4. What's your favorite K-fashion brand?",
        icons: [
            { text: '1. Gentle Monster', imageKey: 'gentleMonster' },
            { text: '2. Instantfunk', imageKey: 'instantfunk' },
            { text: '3. Emis', imageKey: 'emis' },
            { text: '4. Adererror', imageKey: 'ader' },
            { text: '5. Wooyoungmi', imageKey: 'woo' },
            { text: '6. Other', imageKey: 'else' }
        ]
    },
    {
        text: "5. What's your favorite K-beauty brand?",
        icons: [
            { text: '1. Tamburins', imageKey: 'tamburins' },
            { text: '2. Amuse', imageKey: 'amuse' },
            { text: '3. Dr.G', imageKey: 'DrG' },
            { text: '4. JungSaemMool', imageKey: 'jungsaem' },
            { text: '5. Peripera', imageKey: 'peripera' },
            { text: '6. Other', imageKey: 'else' }
        ]
    },
    {
        text: '6. What are you currently\ntrying to accomplish?',
        icons: [
            { text: '1. Learning Korean', imageKey: 'korean' },
            { text: '2. K-pop dance', imageKey: 'kPop' },
            { text: '3. Cooking\nKorean food', imageKey: 'cook' },
            { text: '4. Make Korean\nfriends', imageKey: 'friend' },
            { text: '5. Buy a house\nin Korea', imageKey: 'home' },
            { text: '6. Other', imageKey: 'else' }
        ]
    },
    {
        text: '7. Why is it hard to challenge that?',
        icons: [
            { text: '1. Economic reasons', imageKey: 'economic' },
            { text: '2. Linguistic reasons', imageKey: 'language' },
            { text: '3. Opposites around', imageKey: 'around' },
            { text: '4. Lack of courage', imageKey: 'courage' },
            { text: '5. Lack of motivation', imageKey: 'motivation' },
            { text: '6. Other', imageKey: 'else' }
        ]
    },
    {
        text: '8. How many hours a day\ndo you spend on K-Culture?',
        icons: [
            { text: '1. Less tahn 1 hour', imageKey: 'oneHour' },
            { text: '2. 1~2 hours', imageKey: 'twoHour' },
            { text: '3. 2~3 hours', imageKey: 'threeHour' },
            { text: '4. More than 3 hours', imageKey: 'fourHour' },
        ]
    },
    {
        text: '9. How much do you spend\nper month on K-Culture?',
        icons: [
            { text: '1. 50 USD or less', imageKey: 'oneMoney' },
            { text: '2. 50~300 USD', imageKey: 'twoMoney' },
            { text: '3. 300~500 USD', imageKey: 'threeMoney' },
            { text: '4. More than\n500 USD', imageKey: 'fourMoney' },
        ]
    },
    {
        text: "10. What's the best way\nto keep up your routine?",
        icons: [
            { text: '1. Checklists', imageKey: 'checkList' },
            { text: '2. Reward\nyourslef', imageKey: 'reward' },
            { text: '3. Set a goal', imageKey: 'objective' },
            { text: '4. Visualize\nsuccess', imageKey: 'success' },
            { text: '5. Use the\nRoutines app', imageKey: 'routine' },
            { text: '6. Other', imageKey: 'else' }
        ]
    },
    {
        text: "11. What's most important\nin your life right now?",
        icons: [
            { text: '1. Money', imageKey: 'fourMoney' },
            { text: '2. Family', imageKey: 'family' },
            { text: '3. Hobbies and leisure', imageKey: 'hobby' },
            { text: '4. Work', imageKey: 'work' },
            { text: '5. Rest', imageKey: 'motivation' },
            { text: '6. Other', imageKey: 'else' }
        ]
    },
    {
        text: '12. Which country do you currently live in?',
        icons: [
            { text: '1. South Korea', imageKey: 'korea' },
            { text: '2. USA', imageKey: 'usa' },
            { text: '3. France', imageKey: 'france' },
            { text: '4. Singapore', imageKey: 'singapore' },
            { text: '5. Japan', imageKey: 'japan' },
            { text: '6. Other', imageKey: 'twelveElse' }
        ]
    },
    {
        text: '13. What country would you like\nto live in in the future?',
        icons: [
            { text: '1. South Korea', imageKey: 'korea' },
            { text: '2. USA', imageKey: 'usa' },
            { text: '3. France', imageKey: 'france' },
            { text: '4. Singapore', imageKey: 'singapore' },
            { text: '5. Japan', imageKey: 'japan' },
            { text: '6. Other', imageKey: 'twelveElse' }
        ]
    },
    {
        text: '14. What kind of house\ndo you want to live in?',
        icons: [
            { text: '1. House with\ngarden', imageKey: 'home' },
            { text: '2. Tidy Unit', imageKey: 'work' },
            { text: '3. Simple\nApartment', imageKey: 'apartment' },
            { text: '4. Other', imageKey: 'else' },
        ]
    },
];
