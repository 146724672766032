<template>
    <div class="landing">
        <LanguageToggle />
        <div class="logo">N I S S I</div>
        <div class="svg-container">
            <LandingSVG class="svg" />
        </div>
        <div class="overlay">
            <div v-show="!isEnglish" class="overlay-text">
                <p class="same-style">Me, Myself and I</p>
                <p>온전히 나에게 집중하는 시간</p>
                <p>“당신이 원하는 것 이뤄드릴게요”</p>
            </div>
            <div v-show="isEnglish" class="overlay-text">
                <p class="same-style">Me, Myself and I</p>
                <p>“Time to focus on me”</p>
                <p>We will make your wish come true</p>
            </div>
            <button class="start-button" @click="navigateToNextPage">START</button>
        </div>
    </div>
</template>

<script>
import { onMounted, computed } from 'vue';
import router from '../router';

import LandingSVG from '../svg/LandingSVG.vue';
import LanguageToggle from '../components/LanguageToggle.vue';

import { useLanguageStore } from '../stores/language';

export default {
    name: 'Landing',
    components: {
        LandingSVG,
        LanguageToggle,
    },
    setup() {
        const languageStore = useLanguageStore();

        const isEnglish = computed(() => languageStore.isEnglish);

        const setVH = () => {
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        }

        const navigateToNextPage = () => {
            router.push('/survey');
        }

        onMounted(() => {
            setVH();
            window.addEventListener('resize', setVH);
        });

        return {
            navigateToNextPage,
            isEnglish,
        }
    }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Gowun+Dodum&display=swap');

.landing {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    font-family: 'Gowun Dodum', sans-serif;
}


.logo {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(var(--vh) * 9);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    z-index: 11;
    color: black;
    background-color: white;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.slider {
    position: absolute;
    top: calc(var(--vh) * 7.5);
    width: 100%;
    height: calc(var(--vh) * 92.5);
    overflow: hidden;
    align-items: center;
    justify-content: center;
}

.overlay {
    position: absolute;
    top: calc(var(--vh) * 7.5);
    left: 0;
    width: 100%;
    height: calc(var(--vh) * 92.5);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-top: 20%;
    z-index: 10;
}

.overlay-text {
    text-align: center;
    color: black;
    padding-top: calc(var(--vh) * 23);
}

.overlay-text p {
    margin: 0;
    padding-bottom: 11px;
    font-family: 'Gowun Dodum', sans-serif;
    font-size: 20px;
    opacity: 0;
    animation: slideUp 1s forwards;
}

.overlay-text p.same-style {
    margin: 0;
    padding-bottom: 12px;
    font-family: 'Gowun Dodum', sans-serif;
    font-size: 30px;
    opacity: 0;
    animation: slideUp 1s forwards;
}

.overlay-text p:nth-child(1) {
    animation-delay: 0.5s;
}

.overlay-text p:nth-child(2) {
    animation-delay: 1s;
}

.overlay-text p:nth-child(3) {
    animation-delay: 1.5s;
}

.start-button {
    position: absolute;
    bottom: calc(var(--vh) * 15);
    padding: 0px 40px;
    cursor: pointer;
    z-index: 10;
    opacity: 0;
    animation: slideUp 1s forwards 2s;
    border: none;
    border-radius: 5px;
    background-color: rgba(217, 217, 217, 0.5);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    border-radius: 50px;
    color: #000;
    text-align: center;
    font-family: 'Gowun Dodum', sans-serif;
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: -0.5px;
}

@keyframes slideUp {
    0% {
        transform: translateY(100%);
        opacity: 0;
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

.svg-container {
    position: absolute;
    top: calc(var(--vh) * 50);
    left: 50%;
    width: 100%;
    height: calc(var(--vh) * 75);
    transform: translate(-50%, -50%);
    z-index: 1;
}

.svg {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    z-index: 1;
}
</style>
