import { defineStore } from 'pinia';

export const useLanguageStore = defineStore('language', {
    state: () => ({
        isEnglish: false, // 초기 언어 상태: 한국어
    }),
    actions: {
        setLanguage(isEnglish) {
            this.isEnglish = isEnglish;
            if (this.isEnglish) {
                console.log("언어를 영어로 설정했습니다.");
            } else {
                console.log("언어를 한국어로 설정했습니다.");
            }
        }
    },
});
