<template>
    <EndingContainer v-if="showEnding" @close="handleClose" />
    <div v-show="!showEnding" class="user-info">
        <div class="logo">N I S S I</div>
        <div class="back-button" @click="goBack">
            <img src="@resource/common/backButton.png">
        </div>
        <LanguageToggle />
        <div class="form-content">
            <div class="input-group">
                <label for="gender" class="input-label">{{ isEnglish ? '1.What is your gender?' :
                    '1. 당신의 성별은?' }}</label>
                <div class="gender-options">
                    <button class="gender-button" :class="{ 'active': activeGender === 'female' }"
                        @click="selectGender('female')">{{ isEnglish ? 'Woman' : '여자' }}</button>
                    <button class="gender-button" :class="{ 'active': activeGender === 'male' }"
                        @click="selectGender('male')">{{ isEnglish ? 'Man' : '남자' }}</button>
                    <button class="gender-button" :class="{ 'active': activeGender === 'other' }"
                        @click="selectGender('other')">{{ isEnglish ? 'Other' : '그 외' }}</button>
                </div>
                <p v-if="genderError" class="error-message">{{ isEnglish ? 'Please select your gender.' :
                    '성별을선택해주세요.' }}'</p>
            </div>

            <div class="input-group">
                <label for="birthdate" class="input-label">{{ isEnglish ? '2.What is your date of birth?' :
                    '2.당신의생년월일은?' }}'</label>
                <div class="birthdate-inputs">
                    <div class="birthdate-input-group">
                        <input type="text" v-model="birthYear" maxlength="4" class="birthdate-input"
                            :placeholder="isEnglish ? 'Year' : '년'">
                    </div>
                    <div class="birthdate-input-group">
                        <input type="text" v-model="birthMonth" maxlength="2" class="birthdate-input"
                            :placeholder="isEnglish ? 'Month' : '월'">
                    </div>
                    <div class="birthdate-input-group">
                        <input type="text" v-model="birthDay" maxlength="2" class="birthdate-input"
                            :placeholder="isEnglish ? 'Day' : '일'">
                    </div>
                </div>
                <p v-if="birthdateError" class="error-message">
                    {{ isEnglish ? 'Please enter your date of birth accurately.' : '생년월일을 정확히 입력해주세요.' }}
                </p>
            </div>

            <div class="input-group">
                <label for="nickname" class="input-label nickname-label">
                    {{ isEnglish ? "3.Choose a nickname" : "3. 닉네임을정해주세요." }}
                </label>
                <p class="info-message">{{ isEnglish ? "Only Korean, English, and numbers" : "* 한글, 영문, 숫자만 가능" }}</p>
                <input v-show="!isEnglish" type="text" v-model="nickname" class="input-field" placeholder="닉네임 (최대 10자)"
                    @focus="removePlaceholder" @blur="restorePlaceholder">
                <input v-show="isEnglish" type="text" v-model="nickname" class="input-field"
                    placeholder="Nickname (up to 10 characters)" @focus="removePlaceholder" @blur="restorePlaceholder">
                <p v-if="nicknameError" class="error-message">
                    {{ isEnglish ? "Nickname can be Korean, English, or numbers, up to 10 characters."
                        : "닉네임은 한글, 영문,숫자만 가능하며 10자 이내로 입력해주세요." }}
                </p>
            </div>

            <div class="input-group">
                <label for="email" class="input-label"> {{ isEnglish ? "4. Email address" : "4. 이메일 주소" }}</label>
                <div class="email-inputs">
                    <input type="text" v-model="emailLocalPart" class="input-field email-local-part">
                    <span class="at-symbol">@</span>
                    <div class="input-wrapper">
                        <input type="text" v-model="selectedDomain" @focus="toggleDropdown(true)"
                            @click="toggleDropdown(true)" @input="hideDropdown" class="input-field email-domain-part"
                            :readonly="!isCustomDomain">
                        <div class="dropdown-button" @click="toggleDropdown(!showDropdown)">
                            <img src="@resource/common/dropdown.png">
                        </div>
                        <div v-if="showDropdown" class="dropdown" @mousedown.prevent @click="preventClose">
                            <ul>
                                <li v-show="!isEnglish" @click="selectCustomDomain">직접입력</li>
                                <li v-show="isEnglish" @click="selectCustomDomain">Custom</li>
                                <li v-for="domain in availableDomains" :key="domain" @click="selectDomain(domain)">{{
                                    domain
                                    }}</li>
                            </ul>
                        </div>
                    </div>

                </div>
                <p v-if="emailError" class="error-message">{{ isEnglish ? "Please enter a valid email address."
                    : "올바른 이메일주소를 입력해주세요." }}</p>
            </div>
            <button class="next-button" @click="navigateToNextPage">NEXT</button>
        </div>
    </div>
</template>

<script>
import { onMounted, ref, computed } from 'vue';
import router from '../router';
import EndingContainer from '../components/EndingContainer.vue';

import LanguageToggle from '../components/LanguageToggle.vue';
import { useLanguageStore } from '../stores/language';

export default {
    name: 'UserInfoForm',
    components: {
        EndingContainer,
        LanguageToggle
    },
    setup() {
        const languageStore = useLanguageStore();

        const isEnglish = computed(() => languageStore.isEnglish);

        const showEnding = ref(false);
        const activeGender = ref('');
        const birthYear = ref('');
        const birthMonth = ref('');
        const birthDay = ref('');
        const nickname = ref('');
        const emailLocalPart = ref('');
        const selectedDomain = ref('');
        const emailDomainPart = ref('');

        const showDropdown = ref(false);
        const isCustomDomain = ref(false);
        const availableDomains = ref(['gmail.com', 'naver.com', 'yahoo.com', 'nate.com']);

        const toggleDropdown = (show) => {
            showDropdown.value = show;
        };

        const hideDropdown = () => {
            setTimeout(() => {
                showDropdown.value = false;
            }, 150);
        };

        const preventClose = (event) => {
            event.stopPropagation();
        };

        const selectDomain = (domain) => {
            selectedDomain.value = domain;
            showDropdown.value = false;
            isCustomDomain.value = false;
        };

        const selectCustomDomain = () => {
            isCustomDomain.value = true;
            selectedDomain.value = '';
            showDropdown.value = false;
        };

        const removePlaceholder = (event) => {
            event.target.placeholder = '';
        };

        const restorePlaceholder = (event) => {
            if (isEnglish.value) { event.target.placeholder = 'Nickname (up to 10 characters)'; }
            else {
                event.target.placeholder = '닉네임 (최대 10자)';
            }
        };

        const genderError = ref(false);
        const birthdateError = ref(false);
        const nicknameError = ref(false);
        const emailError = ref(false);

        const selectGender = (gender) => {
            activeGender.value = gender;
            genderError.value = false;
        };

        const setVH = () => {
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        }

        const goBack = () => {
            router.push({
                path: '/survey',
                query: { index: 13 }
            });
        };

        const validateBirthdate = () => {
            const year = parseInt(birthYear.value, 10);
            const month = parseInt(birthMonth.value, 10);
            const day = parseInt(birthDay.value, 10);

            if (
                !year || year < 1900 || year > new Date().getFullYear() ||
                !month || month < 1 || month > 12 ||
                !day || day < 1 || day > 31
            ) {
                birthdateError.value = true;
                return false;
            } else {
                birthdateError.value = false;
                return true;
            }
        };

        const validateNickname = () => {
            const nicknamePattern = /^[ㄱ-ㅎ가-힣a-zA-Z0-9]{1,10}$/;
            if (!nicknamePattern.test(nickname.value)) {
                nicknameError.value = true;
                return false;
            } else {
                nicknameError.value = false;
                return true;
            }
        };

        const validateEmail = () => {
            const emailPattern = /^[^\s@]+$/;
            const domainPattern = /^[^\s@]+\.[^\s@]+$/;

            const domainToValidate = selectedDomain.value === 'custom' ? emailDomainPart.value : selectedDomain.value;

            if (!emailPattern.test(emailLocalPart.value) ||
                !domainToValidate || !domainPattern.test(domainToValidate)) {
                emailError.value = true;
                return false;
            } else {
                emailError.value = false;
                return true;
            }
        };

        const navigateToNextPage = () => {
            let valid = true;

            if (!activeGender.value) {
                genderError.value = true;
                valid = false;
            }

            if (!validateBirthdate()) {
                valid = false;
            }

            if (!validateNickname()) {
                valid = false;
            }

            if (!validateEmail()) {
                valid = false;
            }

            if (valid) {
                localStorage.setItem('userInfo', JSON.stringify({
                    gender: activeGender.value,
                    birthdate: `${birthYear.value}-${birthMonth.value}-${birthDay.value}`,
                    nickname: nickname.value,
                    email: `${emailLocalPart.value}@${selectedDomain.value}`
                }));

                showEnding.value = true;
            }
        }

        const handleClose = () => {
            showEnding.value = false;
        }

        onMounted(() => {
            setVH();
            window.addEventListener('resize', setVH);
        });

        return {
            navigateToNextPage,
            goBack,
            showEnding,
            handleClose,
            activeGender,
            selectGender,
            genderError,
            birthYear,
            birthMonth,
            birthDay,
            nickname,
            birthdateError,
            nicknameError,
            emailError,
            removePlaceholder,
            restorePlaceholder,
            emailLocalPart,
            selectedDomain,
            emailDomainPart,
            showDropdown,
            isCustomDomain,
            availableDomains,
            toggleDropdown,
            hideDropdown,
            selectDomain,
            selectCustomDomain,
            preventClose,
            isEnglish
        }
    }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Gowun+Dodum&display=swap');

.user-info {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    font-family: 'Gowun Dodum', sans-serif;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: calc(var(--vh) * 0) 15px calc(var(--vh) * 5);
    box-sizing: border-box;
}

.logo {
    width: 100%;
    height: calc(var(--vh) * 9);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    z-index: 11;
    color: black;
    background-color: white;
    position: relative;
    top: 0;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);

}

.form-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

}

.next-button {
    padding: 0px 40px;
    cursor: pointer;
    z-index: 10;
    border: none;
    border-radius: 5px;
    fill: rgba(217, 217, 217, 0.5);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    border-radius: 50px;
    color: #000;
    text-align: center;
    font-family: 'Gowun Dodum', sans-serif;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: -0.5px;
    margin-top: 35px;
    align-self: center;
}

.back-button {
    position: absolute;
    top: 21px;
    left: 21px;
    cursor: pointer;
    z-index: 12;
    background-color: white;
}

.input-group {
    margin: 6px 0 11px 0;
    padding: 0 7px;
}

.input-label {
    font-size: 18px;
    margin-bottom: 17px;
    display: block;
    color: black;
}

.gender-options {
    display: flex;
    justify-content: space-between;
    gap: 19px;
}

.gender-button {
    flex: 1;
    padding: 16px;
    font-size: 19px;
    color: #828282;
    border: 1px #E0E0E0;
    background: #FFF;
    border-radius: 8px;
    cursor: pointer;
    text-align: center;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    font-family: 'Gowun Dodum', sans-serif;
    width: calc(33.33% - 13px);
}

.birthdate-inputs {
    display: flex;
    justify-content: space-between;
    gap: 19px;
}

.birthdate-input {
    flex: 1;
    padding: 16px;
    font-size: 19px;
    color: #828282;
    border: 1px #E0E0E0;
    background: #FFF;
    border-radius: 8px;
    text-align: right;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    font-family: 'Gowun Dodum', sans-serif;
    width: 50%;
}

.birthdate-input-group {
    display: flex;
    align-items: flex-end;
}

.birthdate-label {
    margin-left: 5px;
    color: black;
    text-align: center;
    font-family: "Gowun Dodum";
    font-size: 19px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
}

.input-field {
    width: 90%;
    padding: 16px;
    font-size: 19px;
    color: #828282;
    border: 1px #E0E0E0;
    background: #FFF;
    border-radius: 8px;
    text-align: left;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    font-family: 'Gowun Dodum', sans-serif;
}

.info-message {
    color: #1400FF;
    font-family: "Gowun Dodum";
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
}

.nickname-label {
    margin-bottom: 1px;
}

.gender-button.active {
    background-color: #d1d1d1;
    color: #333;
}

.error-message {
    color: red;
    font-size: 14px;
    margin-top: 10px;
}

.email-inputs {
    display: flex;
    align-items: center;
    gap: 6.5px;
}

.email-local-part,
.email-domain-part {
    padding: 16px;
    font-size: 19px;
    color: #828282;
    border: 1px solid #E0E0E0;
    background: #FFF;
    border-radius: 8px;
    text-align: left;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    font-family: 'Gowun Dodum', sans-serif;
}

.email-local-part {
    width: 50%;
}

.email-domain-part {
    width: 75%;
}

.at-symbol {
    color: #000;
    text-align: center;
    font-family: "Gowun Dodum";
    font-size: 19px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
}

.email-domain-select {
    padding: 16px;
    font-size: 19px;
    color: #828282;
    border: 1px solid #E0E0E0;
    background: #FFF;
    border-radius: 8px;
    text-align: left;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    font-family: 'Gowun Dodum', sans-serif;
}

.input-wrapper {
    position: relative;
    width: 100%;
}

.email-domain-part {
    width: calc(100% - 30px);
}

.dropdown-button {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    font-size: 16px;
    color: #828282;
}

.dropdown {
    position: absolute;
    background: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    z-index: 20;
    max-height: 150px;
    overflow-y: auto;
    width: 100%;
    top: calc(var(--vh) * 8.5);
}

.dropdown ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.dropdown li {
    cursor: pointer;
    padding-left: 10px;
}

.dropdown li:hover {
    background-color: #f0f0f0;
}
</style>
