<template>
    <InterMissionContainer v-if="showIntermission" :step="currentIntermissionStep" @close="handleClose" />
    <div v-show="!showIntermission" class="survey">
        <div class="logo">N I S S I</div>
        <div class="back-button" @click="goBack">
            <img src="@resource/common/backButton.png">
        </div>
        <LanguageToggle />
        <div class="progress">
            <div class="progress-text" :data-current="currentQuestionIndex + 1"></div>
            <div class="progress-bar">
                <div class="progress-bar-fill" :style="{ width: `${progressPercentage}%` }"></div>
            </div>
        </div>
        <div class="question">{{ currentQuestion.text }}</div>
        <div class="icon-boxes">
            <div v-for="(icon, index) in paddedIcons" :key="index" @click="icon.src && selectIcon(index)"
                :class="[{ 'inner-shadow': selectedIcon === index, 'invisible-icon': !icon.src }, 'icon-box']">
                <div v-if="icon.src" class="icon-text">{{ icon.text }}</div>
                <img v-if="icon.src" :src="icon.src" alt="Icon" />
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onMounted, computed, watch } from 'vue';
import { useRoute } from 'vue-router';
import router from '../router';
import InterMissionContainer from '../components/InterMissionContainer.vue';
import LanguageToggle from '../components/LanguageToggle.vue';

import { useImageStore } from '../stores/imageStore.js';
import { useLanguageStore } from '../stores/language';

import { koreanQuestions } from '../data/koreanQuestions.js';
import { englishQuestions } from '../data/englishQuestions.js';

export default {
    name: 'survey',
    props: {
        currentQuestionIndex: {
            type: Number,
            default: 0
        }
    },
    components: {
        InterMissionContainer,
        LanguageToggle
    },
    setup(props) {
        const languageStore = useLanguageStore();

        const isEnglish = computed(() => languageStore.isEnglish);

        const route = useRoute();

        const showIntermission = ref(false);
        const currentIntermissionStep = ref(null);
        const currentQuestionIndex = ref(props.currentQuestionIndex);
        const selectedIcon = ref(null);
        const selections = ref([]);

        const imageStore = useImageStore();

        const paddedIcons = computed(() => {

            const icons = [...currentQuestion.value.icons];
            while (icons.length < 6) {
                icons.push({ text: '', src: '' });
            }
            return icons;
        });

        const questions = computed(() => {
            const selectedQuestions = isEnglish.value ? englishQuestions : koreanQuestions;
            return selectedQuestions.map(question => ({
                ...question,
                icons: question.icons.map(icon => ({
                    ...icon,
                    src: icon.imageKey ? imageStore.getImage(icon.imageKey) : ''
                }))
            }));
        });

        const currentQuestion = computed(() => questions.value[currentQuestionIndex.value] || { text: '', icons: [] });

        const progressPercentage = computed(() => ((currentQuestionIndex.value + 1) / questions.value.length) * 100);

        const selectIcon = (index) => {
            selectedIcon.value = index;
            selections.value[currentQuestionIndex.value] = index + 1;
            localStorage.setItem('surveySelections', JSON.stringify(selections.value));

            nextQuestion();
            setTimeout(() => selectedIcon.value = null, 200);
        };

        const nextQuestion = () => {
            if (currentQuestionIndex.value <= questions.value.length - 1) {
                currentQuestionIndex.value++;
            }

            return;
        }

        const goBack = () => {
            if (currentQuestionIndex.value > 0) {
                currentQuestionIndex.value--;
            }
            else {
                router.push('/');
            }
        };

        const handleClose = (buttonType) => {
            showIntermission.value = false;
            if (currentIntermissionStep.value === 1) {
                currentQuestionIndex.value = buttonType === 'start' ? 5 : 4;
            } else if (currentIntermissionStep.value === 2) {
                currentQuestionIndex.value = buttonType === 'start' ? 9 : 8;
            } else if (currentIntermissionStep.value === 3) {
                if (buttonType === 'start') {
                    router.push('/userinfoform');
                } else {
                    currentQuestionIndex.value = 13;
                }
            } else {
                alert(`Error occurred: Please try again: ${currentIntermissionStep.value} ${buttonType}`);
            }
        };

        const setVH = () => {
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        }

        onMounted(() => {
            setVH();
            window.addEventListener('resize', setVH);

            const indexFromQuery = route.query.index;
            if (indexFromQuery) {
                currentQuestionIndex.value = parseInt(indexFromQuery);
            }

            const savedSelections = localStorage.getItem('surveySelections');
            if (savedSelections) {
                selections.value = JSON.parse(savedSelections);
            } else {
                selections.value = Array(14).fill(null);
            }
        });



        watch(currentQuestionIndex, (newVal) => {
            if (newVal === 5) {
                showIntermission.value = true;
                currentIntermissionStep.value = 1;
            } else if (newVal === 9) {
                showIntermission.value = true;
                currentIntermissionStep.value = 2;
            } else if (newVal === 14) {
                showIntermission.value = true;
                currentIntermissionStep.value = 3;
            }
        });

        return {
            currentQuestionIndex,
            currentQuestion,
            progressPercentage,
            selectIcon,
            selectedIcon,
            goBack,
            showIntermission,
            currentIntermissionStep,
            handleClose,
            paddedIcons
        };
    }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Gowun+Dodum&display=swap');

.survey {
    position: relative;
    padding-top: calc(var(--vh) * 7.5);
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    font-family: 'Gowun Dodum', sans-serif;
    background-color: white;
}

.logo {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(var(--vh) * 9);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    color: black;
    background-color: white;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);

}

.progress {
    position: relative;
    left: 0;
    top: calc(var(--vh) * 2);
    width: 100%;
    height: calc(var(--vh) * 12.5);
    padding: 0px 10px 30px 10px;
    box-sizing: border-box;
    text-align: center;
    background-color: rgba(0, 0, 0, 0)
}

.progress-text {
    font-size: 18px;
    margin-bottom: 13px;
    text-align: right;
    font-family: 'Gowun Dodum', sans-serif;
}

.progress-text::before {
    content: attr(data-current);
    color: #F20707
}

.progress-text::after {
    content: ' / 14';
    color: black;
}

.progress-bar {
    width: 90%;
    height: 15px;
    background: #D9D9D9;
    margin: 0 auto;
    border-radius: 20px;
    overflow: hidden;
    padding: 2px;
}

.progress-bar-fill {
    height: 100%;
    background-color: #f6E123;
    border-radius: 20px;
}

.question {
    position: relative;
    margin-bottom: 25px;
    text-align: center;
    font-size: 18px;
    background-color: white;
    color: black;
    font-family: 'Gowun Dodum', sans-serif;
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
    white-space: pre-wrap;
}

.icon-boxes {
    position: relative;
    width: 100%;
    height: calc(var(--vh) * 65);
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: center;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0)
}

.icon-box {
    width: calc(var(--vh) * 17.5);
    height: calc(var(--vh) * 17.5);
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    border: 1px #E0E0E0;
    cursor: pointer;
    user-select: none;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}


.icon-box img {
    width: auto;
    height: 60%;
    object-fit: cover;
    border-radius: 10px;
}

.inner-shadow {
    box-shadow: inset 0px 4px 10px 0px rgba(84, 81, 81, 0.90);
}

.back-button {
    position: absolute;
    top: 21px;
    left: 21px;
    cursor: pointer;
    background-color: white;
}

.icon-text {
    font-family: 'Gowun Dodum', sans-serif;
    color: #000;
    text-align: center;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    white-space: pre-wrap;
    margin-bottom: 8px;
}

.invisible-icon {
    visibility: hidden;
}
</style>
