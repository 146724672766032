export const koreanQuestions = [
    {
        text: '1. 당신이 가장 관심있는 한국 분야는?',
        icons: [
            { text: '1. K-pop', imageKey: 'kPop' },
            { text: '2. K-beauty', imageKey: 'kBeauty' },
            { text: '3. K-food', imageKey: 'kFood' },
            { text: '4. K-drama', imageKey: 'kDrama' },
            { text: '5. K-fashion', imageKey: 'kFashion' },
            { text: '6. 그 외', imageKey: 'oneElse' }
        ]
    },
    {
        text: '2. 당신이 가장 좋아하는 K-셀럽은?',
        icons: [
            { text: '1. 뉴진스', imageKey: 'newJeans' },
            { text: '2. 방탄소년단', imageKey: 'bts' },
            { text: '3. 블랙핑크', imageKey: 'blackPink' },
            { text: '4. 라이즈', imageKey: 'rise' },
            { text: '5. IVE', imageKey: 'ive' },
            { text: '6. NCT 127', imageKey: 'nct' }
        ]
    },
    {
        text: '3. 당신이 가장 좋아하는\n한국 음식은?',
        icons: [
            { text: '1. 부대찌개', imageKey: 'budae' },
            { text: '2. 불닭볶음면', imageKey: 'buldak' },
            { text: '3. 김치전', imageKey: 'kimchi' },
            { text: '4. 간장게장', imageKey: 'ganjang' },
            { text: '5. 떡볶이', imageKey: 'tteokbokki' },
            { text: '6. 그 외', imageKey: 'threeElse' }
        ]
    },
    {
        text: '4. 당신이 가장 좋아하는\n한국 디자이너 브랜드는?',
        icons: [
            { text: '1. 젠틀몬스터', imageKey: 'gentleMonster' },
            { text: '2. 인스턴트펑크', imageKey: 'instantfunk' },
            { text: '3. 이미스', imageKey: 'emis' },
            { text: '4. 아더에러', imageKey: 'ader' },
            { text: '5. 우영미', imageKey: 'woo' },
            { text: '6. 그 외', imageKey: 'else' }
        ]
    },
    {
        text: '5. 당신이 가장 좋아하는\n한국 뷰티 브랜드는?',
        icons: [
            { text: '1. 탬버린즈', imageKey: 'tamburins' },
            { text: '2. 어뮤즈', imageKey: 'amuse' },
            { text: '3. 닥터지', imageKey: 'DrG' },
            { text: '4. 정샘물', imageKey: 'jungsaem' },
            { text: '5. 페리페라', imageKey: 'peripera' },
            { text: '6. 그 외', imageKey: 'else' }
        ]
    },
    {
        text: '6. 현재 당신이 도전해 보고 싶은 것은\n무엇인가요?',
        icons: [
            { text: '1. 한국어 배우기', imageKey: 'korean' },
            { text: '2. 케이팝 댄스', imageKey: 'kPop' },
            { text: '3. 한식 요리하기', imageKey: 'cook' },
            { text: '4. 한국인 친구 사귀기', imageKey: 'friend' },
            { text: '5. 한국 집구매', imageKey: 'home' },
            { text: '6. 그 외', imageKey: 'else' }
        ]
    },
    {
        text: '7. 위 내용을 도전하기\n어려운 이유는 무엇인가요?',
        icons: [
            { text: '1. 경제적 이유', imageKey: 'economic' },
            { text: '2. 언어적 이유', imageKey: 'language' },
            { text: '3. 주변의 반대', imageKey: 'around' },
            { text: '4. 용기 부족', imageKey: 'courage' },
            { text: '5. 동기부여 부족', imageKey: 'motivation' },
            { text: '6. 그 외', imageKey: 'else' }
        ]
    },
    {
        text: '8. K-문화를 위해\n하루에 몇 시간을 소요하나요?',
        icons: [
            { text: '1. 1시간 이하', imageKey: 'oneHour' },
            { text: '2. 1~2시간', imageKey: 'twoHour' },
            { text: '3. 2~3시간', imageKey: 'threeHour' },
            { text: '4. 3시간 이상', imageKey: 'fourHour' },
        ]
    },
    {
        text: '9. K-문화를 위해\n한달에 얼마를 소요하나요?',
        icons: [
            { text: '1. 5만원 이하', imageKey: 'oneMoney' },
            { text: '2. 5~30만원', imageKey: 'twoMoney' },
            { text: '3. 30~50만원', imageKey: 'threeMoney' },
            { text: '4. 50만원 이상', imageKey: 'fourMoney' },
        ]
    },
    {
        text: '10. 당신의 루틴을 지속하기 위해\n제일 좋은 방법은 무엇인가요?',
        icons: [
            { text: '1. 체크리스트', imageKey: 'checkList' },
            { text: '2. 자기자신을\n위한 보상', imageKey: 'reward' },
            { text: '3. 제목표 설정', imageKey: 'objective' },
            { text: '4. 성공의\n시각화', imageKey: 'success' },
            { text: '5. 루틴 앱\n이용하기', imageKey: 'routine' },
            { text: '6. 그 외', imageKey: 'else' }
        ]
    },
    {
        text: '11. 현재 당신의 삶에서 가장 중요한 것은?',
        icons: [
            { text: '1. 돈', imageKey: 'fourMoney' },
            { text: '2. 가족', imageKey: 'family' },
            { text: '3. 취미, 여가활동', imageKey: 'hobby' },
            { text: '4. 일', imageKey: 'work' },
            { text: '5. 휴식', imageKey: 'motivation' },
            { text: '6. 그 외', imageKey: 'else' }
        ]
    },
    {
        text: '12. 현재 당신이 살고있는 나라는?',
        icons: [
            { text: '1. 한국', imageKey: 'korea' },
            { text: '2. 미국', imageKey: 'usa' },
            { text: '3. 프랑스', imageKey: 'france' },
            { text: '4. 싱가포르', imageKey: 'singapore' },
            { text: '5. 일본', imageKey: 'japan' },
            { text: '6. 그 외', imageKey: 'twelveElse' }
        ]
    },
    {
        text: '13. 미래에 당신이 살고 싶은 나라는?',
        icons: [
            { text: '1. 한국', imageKey: 'korea' },
            { text: '2. 미국', imageKey: 'usa' },
            { text: '3. 프랑스', imageKey: 'france' },
            { text: '4. 싱가포르', imageKey: 'singapore' },
            { text: '5. 일본', imageKey: 'japan' },
            { text: '6. 그 외', imageKey: 'twelveElse' }
        ]
    },
    {
        text: '14. 살고 싶은 집의 형태는?',
        icons: [
            { text: '1. 정원있는 주택', imageKey: 'home' },
            { text: '2. 심플한\n오피스텔', imageKey: 'work' },
            { text: '3. 평범한 아파트', imageKey: 'apartment' },
            { text: '4. 그 외', imageKey: 'else' },
        ]
    },
];
