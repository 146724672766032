<template>
    <div class="inter-mission">
        <div class="logo">N I S S I</div>
        <div class="back-button" @click="handleBack()">
            <img src="@resource/common/backButton.png">
        </div>
        <LanguageToggle />
        <div class="svg-container">
            <component :is="currentComponent" class="svg" />
        </div>
        <div class="overlay">
            <div class="overlay-text">
                <p>{{ currentText.line1 }}</p>
                <p>{{ currentText.line2 }}</p>
            </div>

            <button class="next-button" @click="handleNext()">NEXT</button>
        </div>
    </div>
</template>

<script>
import { computed, onMounted } from 'vue';
import LanguageToggle from '../components/LanguageToggle.vue';
import { useLanguageStore } from '../stores/language';

import InterMissionSVG1 from '../svg/InterMissionSVG1.vue';
import InterMissionSVG2 from '../svg/InterMissionSVG2.vue';
import InterMissionSVG3 from '../svg/InterMissionSVG3.vue';

export default {
    name: 'InterMissionContainer',
    components: {
        InterMissionSVG1,
        InterMissionSVG2,
        InterMissionSVG3,
        LanguageToggle
    },
    props: {
        step: {
            type: Number,
            required: true
        }
    },
    setup(props, { emit }) {
        const languageStore = useLanguageStore();

        const isEnglish = computed(() => languageStore.isEnglish);

        const texts = [
            {
                korean: { line1: 'K-culture를 통해', line2: '새로운 꿈을 만나보세요!' },
                english: { line1: 'Meet new dreams', line2: ' through K-Culture!' }
            },
            {
                korean: { line1: '당신이 원하는 K-dream', line2: '현실로 만들어보세요!' },
                english: { line1: 'Make your K-dreams come true!', line2: '' }
            },
            {
                korean: { line1: 'K-culture의 꿈을 향해', line2: '새로운 도전을 시작하세요!' },
                english: { line1: 'Start a new challenge', line2: 'to fulfill your K-culture dreams!' }
            }
        ];

        const currentText = computed(() => {
            const stepIndex = props.step - 1;
            if (texts[stepIndex]) {
                return isEnglish.value ? texts[stepIndex].english : texts[stepIndex].korean;
            }
            return { line1: '', line2: '' };
        });

        const currentComponent = computed(() => {
            switch (props.step) {
                case 1:
                    return 'InterMissionSVG1';
                case 2:
                    return 'InterMissionSVG2';
                case 3:
                    return 'InterMissionSVG3';
            }
        });


        const setVH = () => {
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        }

        const handleBack = () => {
            emit('close', 'back');
        }

        const handleNext = () => {
            emit('close', 'start');
        }

        onMounted(() => {
            setVH();
            window.addEventListener('resize', setVH);
        });

        return {
            handleBack,
            handleNext,
            currentText,
            currentComponent
        }
    }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Gowun+Dodum&display=swap');

.inter-mission {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    font-family: 'Gowun Dodum', sans-serif;
    background-color: white;
    z-index: 3;
}

.logo {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(var(--vh) * 9);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    color: black;
    background-color: white;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.overlay {
    position: absolute;
    top: calc(var(--vh) * 7.5);
    left: 0;
    width: 100%;
    height: calc(var(--vh) * 92.5);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: white;

}

.overlay-text {
    text-align: center;
    color: black;
    padding-bottom: calc(var(--vh) * 5);

}

.overlay-text p {
    margin: 0;
    font-family: 'Gowun Dodum', sans-serif;
    font-size: 25px;
    opacity: 0;
    animation: slideUp 1s forwards;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: -0.5px;
}

.overlay-text p:nth-child(1) {
    animation-delay: 0.5s;
}

.overlay-text p:nth-child(2) {
    animation-delay: 1s;
}


.next-button {
    position: absolute;
    bottom: calc(var(--vh) * 5);
    padding: 0px 40px;
    cursor: pointer;
    opacity: 0;
    animation: slideUp 1s forwards 2s;
    border: none;
    border-radius: 5px;
    fill: rgba(217, 217, 217, 0.25);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    border-radius: 50px;
    color: #000;
    text-align: center;
    font-family: 'Gowun Dodum', sans-serif;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: -0.5px;
}

@keyframes slideUp {
    0% {
        transform: translateY(100%);
        opacity: 0;
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

.svg-container {
    position: absolute;
    top: calc(var(--vh) * 50);
    left: 50%;
    width: 100%;
    height: calc(var(--vh) * 75);
    transform: translate(-50%, -50%);
    z-index: 1;
}

.svg {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    z-index: 1;
}

.back-button {
    position: absolute;
    top: 21px;
    left: 21px;
    cursor: pointer;
    background-color: white;
}
</style>
