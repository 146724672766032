<template>
    <div class="dream-board-page">
        <div class="center-box">
            <div class="content-container">
                <div class="image-container">
                    <img :src="selectedIdolImage" alt="Image" />
                </div>
                <div class="text-container">
                    <p>
                        <span class="nickname">{{ nickname }}</span>{{ isEnglish ? '’s' : "님," }} <br />
                        {{ isEnglish ? 'First Dreamboard is' : '당신의 첫번째 드림보드는' }}<br />
                        <span class="nickname">{{ idolName }}</span>{{ isEnglish ? '.' : '입니다.' }}<br />
                    </p>
                </div>
                <div class="bottom-text-container">
                    <div v-if="!isEnglish" class="bottom-text-top">
                        <p>당신의 최애 {{ idolName }}와</p>
                        <p>더 가까워지도록 도와드릴게요!</p>
                    </div>
                    <div v-if="isEnglish" class="bottom-text-top">
                        <p>We will help you get closer</p>
                        <p>to your Favorite K-star, {{ idolName }}!</p>
                    </div>
                    <div class="bottom-text-bottom">
                        <p>{{ isEnglish ? '[DreamBoard for 2024]' : '[2024년 드림보드]' }}</p>
                        <ol>
                            <li v-for="(task, index) in selectedTasks" :key="index">{{ task }}</li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onMounted, computed } from 'vue';
import { useLanguageStore } from '../stores/language';

export default {
    name: 'DreamBoardTwo',
    setup() {
        const languageStore = useLanguageStore();
        const isEnglish = computed(() => languageStore.isEnglish);

        const idolImage = ref([
            new URL('@resource/dream_board/six/idol_img1.png', import.meta.url).href,
            new URL('@resource/dream_board/six/idol_img2.png', import.meta.url).href,
            new URL('@resource/dream_board/six/idol_img3.png', import.meta.url).href,
            new URL('@resource/dream_board/six/idol_img4.png', import.meta.url).href,
            new URL('@resource/dream_board/six/idol_img5.png', import.meta.url).href,
            new URL('@resource/dream_board/six/idol_img6.png', import.meta.url).href,
        ]);

        const nickname = ref('닉네임');
        const surveySelections = ref([]);

        const idolTasksMap = {
            1: {
                ko: [
                    '뉴진스의 "Attention" 부르기',
                    '팬미팅 한국어 편지 전달하기',
                    '한국어 응원법 익히기',
                ],
                en: [
                    'Singing the New Jeans "Attention"',
                    'Deliver a Korean letter at a fan metting',
                    'Learning how to cheer in Korean',
                ],
            },
            2: {
                ko: [
                    '방탄소년단의 "봄날" 부르기',
                    '팬미팅 한국어 편지 전달하기',
                    '한국어 응원법 익히기',
                ],
                en: [
                    'Singing the BTS "Spring Day"',
                    'Deliver a Korean letter at a fan metting',
                    'Learning how to cheer in Korean',
                ],
            },
            3: {
                ko: [
                    '블랙핑크의 "뚜두뚜두" 부르기',
                    '팬미팅 한국어 편지 전달하기',
                    '한국어 응원법 익히기',
                ],
                en: [
                    'Singing the BLACKPINK "DDU-DU DDU-DU"',
                    'Deliver a Korean letter at a fan metting',
                    'Learning how to cheer in Korean',
                ],
            },
            4: {
                ko: [
                    '라이즈의 "Love 911" 부르기',
                    '팬미팅 한국어 편지 전달하기',
                    '한국어 응원법 익히기',
                ],
                en: [
                    'Singint the Riize "Love 911"',
                    'Deliver a Korean letter at a fan metting',
                    'Learning how to cheer in Korean',
                ],
            },
            5: {
                ko: [
                    '아이브의 "I AM" 부르기',
                    '팬미팅 한국어 편지 전달하기',
                    '한국어 응원법 익히기',
                ],
                en: [
                    'Singing the IVE "I AM"',
                    'Deliver a Korean letter at a fan metting',
                    'Learning how to cheer in Korean',
                ],
            },
            6: {
                ko: [
                    'NCT127의 "영웅" 부르기',
                    '팬미팅 한국어 편지 전달하기',
                    '한국어 응원법 익히기',
                ],
                en: [
                    'Singing the NCT 127 "Kick It"',
                    'Deliver a Korean letter at a fan metting',
                    'Learning how to cheer in Korean',
                ],
            },
        };

        const selection = computed(() => {
            return surveySelections.value[1] || 1;
        });

        const selectedIdolImage = computed(() => {
            const selection = surveySelections.value[1] || 1;
            return idolImage.value[selection - 1] || idolImage.value[0];
        });

        const selectedTasks = computed(() => {
            const selection = surveySelections.value[1] || 1;
            const idolTasks = idolTasksMap[selection] || {};
            return isEnglish.value ? idolTasks.en || [] : idolTasks.ko || [];
        });

        const idolName = computed(() => {
            if (selection.value === 1) {
                return isEnglish.value ? 'Newjeans' : '뉴진스';
            } else if (selection.value === 2) {
                return isEnglish.value ? 'BTS' : '방탄소년단';
            } else if (selection.value === 3) {
                return isEnglish.value ? 'BLACKPINK' : '블랙핑크';
            } else if (selection.value === 4) {
                return isEnglish.value ? 'Riize' : '라이즈';
            } else if (selection.value === 5) {
                return isEnglish.value ? 'IVE' : '아이브';
            } else {
                return 'NCT 127';
            }
        });

        const setVH = () => {
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        };

        onMounted(() => {
            setVH();
            window.addEventListener('resize', setVH);

            const userInfo = JSON.parse(localStorage.getItem('userInfo') || '{}');
            nickname.value = userInfo.nickname || '닉네임';

            surveySelections.value = JSON.parse(localStorage.getItem('surveySelections') || '[]');
        });

        return {
            nickname,
            idolName,
            selectedIdolImage,
            selectedTasks,
            isEnglish
        };
    },
};
</script>


<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Gowun+Dodum&display=swap');

.dream-board-page {
    width: 100%;
    height: calc(var(--vh) * 67.5);
    background: transparent;
    color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    box-sizing: border-box;
    position: relative;
}

.center-box {
    width: 92.5%;
    max-width: 500px;
    height: calc(var(--vh) * 62.5);
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
}

.content-container {
    width: 100%;
    height: 100%;
    position: relative;
}

.image-container {
    position: absolute;
    top: 22.5%;
    left: 20%;
    width: 35%;
    height: 30%;
    transform: translate(-50%, -50%);
}

.image-container img {
    width: 100%;
    height: 100%;
    border-radius: 30px;
    object-fit: cover;
}

.text-container {
    position: absolute;
    top: 0%;
    left: 37.5%;
    width: 60%;
    height: 40%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: left;
}

.text-container p {
    color: #000;
    font-family: "Gowun Dodum";
    font-size: 19px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: -0.38px;
}

.nickname {
    color: #000;
    font-family: "Gowun Dodum";
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: -0.5px;
}

.bottom-text-container {
    position: absolute;
    bottom: 90px;
    left: 0;
    width: 100%;
    height: 40%;
    padding-left: 28px;
    box-sizing: border-box;
}

.bottom-text-top p {
    color: #000;
    font-family: "Gowun Dodum";
    font-size: 19px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: -0.38px;
}

.bottom-text-bottom {
    margin-top: 25px;
}

.bottom-text-bottom p {
    color: #000;
    font-family: "Gowun Dodum";
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: -0.5px;
    margin: 0 0 10px 0;
}

.bottom-text-container ol {
    margin: 0;
    padding-left: 20px;
}

.bottom-text-container li {
    margin-bottom: 5px;
    color: #000;
    font-family: "Gowun Dodum";
    font-size: 19px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: -0.38px;
}
</style>
